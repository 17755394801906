import React from 'react'
import Layout from "../../components/layout"
import BackgroundImage from 'gatsby-background-image'
import { graphql, Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import Checkout from '../../components/checkoutButton'

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    productionsWrapper: {
      paddingBottom: "1em",
      maxWidth: "85rem",
      margin: "20px auto",
      display: "flex",
      flexDirection: "column"
    },
    showsWrapper: {
      paddingBottom: "1em",
      maxWidth: "60rem",
      margin: "20px auto",
      display: "flex",
      flexDirection: "column"
    },
    jumbo: {
      height: "100vh",
      backgroundAttachment: "fixed"
  
    },
    dropTop: {
      paddingTop: "100%"
    }
  }));
  
  export const query = graphql`
      query {
        jumbo: file(relativePath: { eq: "much-ado/ado-wordless-banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        frankenstein: file(relativePath: { eq: "FrankensteinPromo-65.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        wine: file(relativePath: { eq: "wine.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        podplays: file(relativePath: { eq: "podplays.jpeg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        cityLights: file(relativePath: { eq: "cityLights.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        artsAtlanta: file(relativePath: { eq: "artsAtlanta.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        nichole: file(relativePath: { eq: "nichole.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        elizabethVictor: file(relativePath: { eq: "victor-elizabeth-optimized.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        },
      }
    `

export default function Index({ data }) {

    const classes = useStyles();

    return (
        <div>
            <Layout>
            <BackgroundImage 
                Tag="section"
                className={classes.jumbo}
                fluid={data.jumbo.childImageSharp.fluid}
                backgroundColor={`#040e18`}
                >
                <div class="jumbo-inner-wrapper-frankenstein">
                    <div style={{maxWidth:'800px'}}>
                        {/* <h1 class="jumbo-title-wine">
                            New Plays, No walls
                        </h1> */}
                        <h6 class="subtitle-muchado">Ever wish you could live out a Shakespearean play in real life? Well, now's your chance! </h6> 
                        <br></br>
                        <h6 class="subtitle-muchado">This modern Much Ado casts you as best friend to Beatrice, Benedick and Hero. Get the inside scoop directly from the sources of the drama! </h6> 
                        <br></br>
                        <h6 class="subtitle-muchado">This text-message experience is like Gossip Girl meets your best-friend text-chain rolled into an immersive theatrical world.</h6>  
                        
                        <div className="much-ado-button-wrap">
                            <div class="wine-buttons">
                                {/* <Checkout /> */}
                                <a title="Find out more" href="#price-option" class="button button--bigger blackButton--border">
                                Sign Up
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                </BackgroundImage>
                <div class="news-title-wrapper">
                  <div id="price-option" class="pricing-title">
                      <h2>Pricing Options</h2>
                      <p>We could all use more joy in our lives right now! Because of that, we're offering this experience on a sliding scale. Choose your own pricing option below. You'll receive the same custom-made text-play experience no matter which you choose. If you have the means, please consider the Pay It Forward option to help us continue providing this experience at free and subsidized rates for those who need it.</p>
                  </div>
                </div>
              <div class="show-cards-wrapper">
                <div class="qtr-wrapper">
                  <div class="top price-1-img">
                  </div>
                  <div class="bottom">
                    <h5 class="card-title centered-text">Pay It Forward: $32</h5>
                    {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                    {/* <p class="production-card__locations">Pay It Forward</p> */}
                    <div class="links-container">
                            {/* <a title="Find out more about Them!" target="_blank" rel="noopener noreferrer" href="https://www.ajc.com/entertainment/arts--theater/women-run-theater-companies-push-the-bounds-the-artform/otjHwlzc3Gkxy59wCw6mQI/" class="fullwidth button button--medium button--border">Checkout</a> */}
                            <Checkout priceID="price_1KG4JGC4pq0QCbLS6Tt07cez" />
                    </div>
                  </div>
                </div>
                <div class="qtr-wrapper">
                  <div class="top price-2-img">
                  </div>
                  <div class="bottom">
                    <h5 class="card-title centered-text">Full Price: $16</h5>
                    {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                    {/* <p class="production-card__locations">Full Price</p> */}
                    <div class="links-container">
                    <Checkout priceID="price_1Ia1DUC4pq0QCbLSh5OnBuei" />       
                     {/* <a title="Checkout about Them!" target="_blank" rel="noopener noreferrer" href="https://www.ajc.com/entertainment/arts--theater/women-run-theater-companies-push-the-bounds-the-artform/otjHwlzc3Gkxy59wCw6mQI/" class="fullwidth button button--medium button--border">Checkout</a> */}
                    
                    </div>
                  </div>
                </div>
                <div class="qtr-wrapper">
                  <div class="top price-3-img">
                  </div>
                  <div class="bottom">
                    <h5 class="card-title centered-text">Subsidized: $8</h5>
                    {/* <span class="card-subtitle"> $8 </span> */}
                    {/* <p class="production-card__locations"> Subsidized </p> */}
                    <div class="links-container">
                    <Checkout priceID="price_1KG4KBC4pq0QCbLSOOchkJl8" />       
                    </div>
                  </div>
                </div>
                <div class="qtr-wrapper">
                  <div class="top price-4-img">
                  </div>
                  <div class="bottom">
                    <h5 class="card-title centered-text">Free</h5>
                    {/* <span class="card-subtitle">27/06/2019 –     06/07/2019</span> */}
                    {/* <p class="production-card__locations">Free</p> */}
                    <div class="links-container">
                        <Link className="checkout-link" to="/much-ado-sign-up">
                          <button class="fullwidth button button--bigger blackButton--border">
                            Checkout
                          </button>      
                        </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="news-title-wrapper">
                  <div class="pricing-title">
                      <h3>This text-message-play experience was created by:</h3>
                      <h4>Neeley Gossett, Playwright</h4>
                      <h4>Nichole Palmietto, Producer</h4>
                      <h4>Christopher Fairchild, Graphic Designer</h4>
                      <h4>Josh Akeman, Software Engineer</h4>

                      <h3>This project was made possible thanks to the City of Atlanta's Power2Give campaign, and the generous support of the following donors:</h3>
                      <h4>Emika Abe</h4>
                      <h4>Helen Baker</h4>
                      <h4>Rona Baker</h4>
                      <h4>Paul Boshears</h4>
                      <h4>Amy Carter</h4>
                      <h4>Christine Tryba-Cofrin & David Cofrin</h4>
                      <h4>Kathy Dolan</h4>
                      <h4>Joey Florez</h4>
                      <h4>Alison Hudson Harrell</h4> 
                      <h4>Seth Koslov</h4>
                      <h4>Amy Levin</h4>
                      <h4>Stephanie McFarlane</h4>
                      <h4>Bettyann Van Metter</h4>
                  </div>
                </div>
            </Layout>
        </div>
    )
}
